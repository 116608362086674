var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"update-modal","no-close-on-backdrop":"","ok-title":"確認","centered":"","header-bg-variant":"primary","ok-only":""},on:{"hidden":_vm.handleHide,"close":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h4',{staticClass:"m-0 model-header"},[_vm._v(" "+_vm._s(_vm.customerDataItem.id ? '編輯會員' : '新增會員')+" ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isBusy},on:{"click":_vm.handleOk}},[(!_vm.isBusy)?_c('span',[_vm._v("確認")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)]},proxy:true}])},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 狀態 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{attrs:{"options":_vm.stateOptions,"clearable":false,"reduce":function (option) { return option.value; },"placeholder":"請選擇狀態"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-50 border state-dot",class:{ 'dot-gray': !option.value, 'dot-green-selected': option.value }}),_vm._v(" "+_vm._s(option.label)+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"mr-50 border state-dot",class:{ 'dot-gray': !option.value, 'dot-green-selected': option.value }}),_vm._v(" "+_vm._s(option.label)+" ")])]}}],null,true),model:{value:(_vm.customerData.state),callback:function ($$v) {_vm.$set(_vm.customerData, "state", $$v)},expression:"customerData.state"}})],1),_c('b-form-group',{attrs:{"label-for":"branch_id"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 分站 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])]},proxy:true}],null,true)},[_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.branchOptions,"clearable":false,"reduce":function (option) { return option.value; },"placeholder":"請選擇分站","disabled":_vm.customerDataItem.id ? true : false},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" 沒有"),_c('em',[_vm._v(_vm._s(search))]),_vm._v(" 的相關結果 ")]:_c('div',[_c('small',[_vm._v("暫無資料")])])]}},{key:"option",fn:function(option){return [_c('div',[(option.label)?_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.label)),_c('small',{staticClass:"text-muted"},[_vm._v("( "+_vm._s(option.key)+" )")])]):_c('div',[_vm._v(" "+_vm._s(option.key)+" ")])])]}},{key:"selected-option",fn:function(option){return [_c('div',[(option.label)?_c('div',{staticClass:"font-weight-bold d-block text-nowrap mb-0"},[_vm._v(" "+_vm._s(option.label)),_c('small',{staticClass:"text-muted"},[_vm._v("( "+_vm._s(option.key)+" )")])]):_c('div',[_vm._v(" "+_vm._s(option.key)+" ")])])]}}],null,true),model:{value:(_vm.customerData.branch_id),callback:function ($$v) {_vm.$set(_vm.customerData, "branch_id", $$v)},expression:"customerData.branch_id"}})],1),_c('validation-provider',{attrs:{"name":"帳號","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"account"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 帳號 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"account","state":errors.length > 0 ? false : null,"trim":"","disabled":_vm.customerDataItem.id ? true : false,"placeholder":_vm.customerDataItem.account ? _vm.customerDataItem.account : '請輸入帳號'},model:{value:(_vm.customerData.account),callback:function ($$v) {_vm.$set(_vm.customerData, "account", $$v)},expression:"customerData.account"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"名字","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 名字 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"trim":"","placeholder":_vm.customerDataItem.name ? _vm.customerDataItem.name : '請輸入名字'},model:{value:(_vm.customerData.name),callback:function ($$v) {_vm.$set(_vm.customerData, "name", $$v)},expression:"customerData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"姓氏","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"family_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(" 姓氏 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"family_name","state":errors.length > 0 ? false : null,"trim":"","placeholder":_vm.customerDataItem.family_name ? _vm.customerDataItem.family_name : '請輸入姓氏'},model:{value:(_vm.customerData.family_name),callback:function ($$v) {_vm.$set(_vm.customerData, "family_name", $$v)},expression:"customerData.family_name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),(!_vm.customerDataItem.id)?_c('validation-provider',{attrs:{"rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"customerData-password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 密碼設定 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('span',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"d-flex align-items-center actions-link-btn",attrs:{"title":"密碼產生器"},on:{"click":function () { _vm.$refs.randomGenerator.getData() }}},[_c('b-img',{staticClass:"actions-link-btn-image",attrs:{"src":"/dashboard/admin/images/table/setting.svg","rounded":""}})],1)])])]},proxy:true}],null,true)},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"customerData-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"customerData-password","placeholder":"請輸入管理者密碼"},model:{value:(_vm.customerData.password),callback:function ($$v) {_vm.$set(_vm.customerData, "password", $$v)},expression:"customerData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePassword}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(_vm.customerDataItem.id)?_c('validation-provider',{attrs:{"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"customerData-password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('label',{staticClass:"mb-0"},[_vm._v(" 變更密碼 ")]),_c('span',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-secondary",modifiers:{"hover":true,"v-secondary":true}}],staticClass:"d-flex align-items-center actions-link-btn",attrs:{"title":"密碼產生器"},on:{"click":function () { _vm.$refs.randomGenerator.getData() }}},[_c('b-img',{staticClass:"actions-link-btn-image",attrs:{"src":"/dashboard/admin/images/table/setting.svg","rounded":""}})],1)])])]},proxy:true}],null,true)},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"customerData-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"customerData-password","placeholder":"請輸入會員密碼"},model:{value:(_vm.customerData.password),callback:function ($$v) {_vm.$set(_vm.customerData, "password", $$v)},expression:"customerData.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePassword}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('random-generator',{ref:"randomGenerator",attrs:{"department-explorer-id":"randomGenerator","department-explorer-type":"modal","is-hiden-emit":false,"use-callback-data":true},on:{"call-back-data":function (item) { return _vm.customerData.password = item; }}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }