<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ customerDataItem.id ? '編輯會員' : '新增會員' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 狀態 -->
        <b-form-group label-for="status">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                狀態
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="customerData.state"
            :options="stateOptions"
            :clearable="false"
            :reduce="option => option.value"
            placeholder="請選擇狀態"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>

            <template v-slot:option="option">
              <div class="d-flex align-items-center">
                <span
                  class="mr-50 border state-dot"
                  :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                />
                {{ option.label }}
              </div>
            </template>

            <template #selected-option="option">
              <div class="d-flex align-items-center">
                <span
                  class="mr-50 border state-dot"
                  :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                />
                {{ option.label }}
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 分站 -->
        <b-form-group label-for="branch_id">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                分站
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="customerData.branch_id"
            :options="branchOptions"
            :clearable="false"
            :reduce="option => option.value"
            placeholder="請選擇分站"
            :disabled="customerDataItem.id ? true : false"
            class="w-100"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>

            <template v-slot:option="option">
              <div>
                <div
                  v-if="option.label"
                  class="font-weight-bold d-block text-nowrap mb-0"
                >
                  {{ option.label }}<small class="text-muted">( {{ option.key }} )</small>
                </div>

                <div v-else>
                  {{ option.key }}
                </div>
              </div>
            </template>

            <template #selected-option="option">
              <div>
                <div
                  v-if="option.label"
                  class="font-weight-bold d-block text-nowrap mb-0"
                >
                  {{ option.label }}<small class="text-muted">( {{ option.key }} )</small>
                </div>

                <div v-else>
                  {{ option.key }}
                </div>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 帳號 -->
        <validation-provider
          #default="{ errors }"
          name="帳號"
          rules="required"
        >
          <b-form-group label-for="account">
            <template #label>
              <label class="mb-0">
                帳號
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="account"
              v-model="customerData.account"
              :state="errors.length > 0 ? false : null"
              trim
              :disabled="customerDataItem.id ? true : false"
              :placeholder="customerDataItem.account ? customerDataItem.account : '請輸入帳號'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <!-- 會員名字 -->
            <validation-provider
              #default="{ errors }"
              name="名字"
              rules="required"
            >
              <b-form-group label-for="name">
                <template #label>
                  <label class="mb-0">
                    名字
                    <span class="text-danger">*</span>
                  </label>
                </template>
                <b-form-input
                  id="name"
                  v-model="customerData.name"
                  :state="errors.length > 0 ? false : null"
                  trim
                  :placeholder="customerDataItem.name ? customerDataItem.name : '請輸入名字'"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <!-- 會員姓氏 -->
            <validation-provider
              #default="{ errors }"
              name="姓氏"
              rules="required"
            >
              <b-form-group label-for="family_name">
                <template #label>
                  <label class="mb-0">
                    姓氏
                    <span class="text-danger">*</span>
                  </label>
                </template>
                <b-form-input
                  id="family_name"
                  v-model="customerData.family_name"
                  :state="errors.length > 0 ? false : null"
                  trim
                  :placeholder="customerDataItem.family_name ? customerDataItem.family_name : '請輸入姓氏'"
                />

                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- 密碼設定(新增模式) -->
        <validation-provider
          v-if="!customerDataItem.id"
          #default="{ errors }"
          rules="required"
          name="password"
        >

          <b-form-group label-for="customerData-password">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  密碼設定
                  <span class="text-danger">*</span>
                </label>

                <span>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="密碼產生器"
                    class="d-flex align-items-center actions-link-btn"
                    @click="() => { $refs.randomGenerator.getData() }"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </span>
              </div>
            </template>
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="customerData-password"
                v-model="customerData.password"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="customerData-password"
                placeholder="請輸入管理者密碼"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword"
                />
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- 變更密碼(編輯模式) -->
        <validation-provider
          v-if="customerDataItem.id"
          #default="{ errors }"
          name="password"
        >

          <b-form-group label-for="customerData-password">
            <!-- <template #label>
              <label class="mb-0">
                變更密碼
              </label>
            </template> -->

            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  變更密碼
                </label>

                <span>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="密碼產生器"
                    class="d-flex align-items-center actions-link-btn"
                    @click="() => { $refs.randomGenerator.getData() }"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </span>
              </div>
            </template>

            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="customerData-password"
                v-model="customerData.password"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="customerData-password"
                placeholder="請輸入會員密碼"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword"
                />
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <random-generator
          ref="randomGenerator"
          department-explorer-id="randomGenerator"
          department-explorer-type="modal"
          :is-hiden-emit="false"
          :use-callback-data="true"
          @call-back-data="(item) => customerData.password = item"
        />
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BInputGroupAppend,
  BInputGroup, BImg, VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
// import AdminBranchModal from '@/layouts/components/Modal/admin-branch-modal/branchModal.vue'
import RandomGenerator from '@/layouts/components/Modal/random-modal/RandomModal.vue'
import { useCustomerList, useCustomerSetting } from '../useCustomer'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // BFormSpinbutton,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    RandomGenerator,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    customerDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      password,
      missingFields: [],
      passwordFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    // 顯示密碼/隱藏密碼
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.reseCustomerData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (!this.customerData.branch_id) {
        this.useAlertToast(false, '尚未選擇分站')
        return
      }

      // 判斷必填欄位是否填寫
      const requiredFields = {
        branch_id: '分站',
        name: '名字',
        family_name: '姓氏',
        account: '帳號',
      }

      if (!this.customerDataItem.id) requiredFields.password = '密碼設定'

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.customerData[Object.keys(requiredFields)[i]] === null || this.customerData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      if (this.customerDataItem.id) {
        const submitUpdate = () => {
          this.setCustomerUpdate({
            customer_id: this.customerDataItem.id,
            data: {
              ...this.customerData,
            },
          })
            .then(response => {
              this.useHttpCodeAlert(response)
              this.$emit('refetch-data')
              this.reseCustomerData()
              this.$nextTick(() => {
                this.$bvModal.hide('update-modal')
              })
              this.isBusy = false
            })
            .catch(error => {
              this.useHttpCodeAlert(error.response)
              this.isBusy = false
            })
        }

        if (this.customerData.password) {
          this.setCustomerPasswordUpdate({
            customer_id: this.customerDataItem.id,
            data: {
              newPassword: this.customerData.password,
            },
          })
            .then(() => {
              submitUpdate()
            })
            .catch(error => {
              this.useHttpCodeAlert(error.response)
              this.isBusy = false
            })
        } else submitUpdate()
      }

      if (!this.customerDataItem.id) {
        this.setCustomerCreate({
          ...this.customerData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.reseCustomerData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData({ key: 'branch', queryParams: { noPagination: true } }) //
        .then(response => {
          const { data } = response.data.data

          this.branchOptions = data.map(item => ({
            label: item.name,
            key: item.branch,
            icon: item.icon,
            value: item.id,
          }))
          const findObject = this.branchOptions.find(el => el.id === this.customerDataItem.branch_id)

          if (findObject === undefined) {
            this.customerData.branch_id = null
          }
        })
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankCustomerData, this.customerDataItem),
      }
      this.customerData = resolveData
      this.$bvModal.show('update-modal')
      this.getMetaListData({ key: 'branch', queryParams: { noPagination: true } })
        .then(response => {
          const { data } = response.data.data

          this.branchOptions = data.map(item => ({
            label: item.name,
            key: item.branch,
            icon: item.icon,
            value: item.id,
          }))

          const findObject = this.branchOptions.find(el => el.value === this.customerDataItem.branch_id)

          if (findObject === undefined) {
            this.customerData.branch_id = null
            return
          }
          this.customerData.branch_id = resolveData.branch_id
        })
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      setCustomerCreate,
      setCustomerUpdate,
      setCustomerPasswordUpdate,
      branchOptions,
      blankbranchInfo,
    } = useCustomerList()

    const {
      stateOptions,
      getMetaListData,
      syncObject,
    } = useCustomerSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankCustomerData = {
      id: null,
      name: null,
      family_name: null,
      branch_id: null,
      account: null,
      password: null,
      state: false,
    }

    const customerData = ref(JSON.parse(JSON.stringify(blankCustomerData)))

    const reseCustomerData = () => {
      customerData.value = JSON.parse(JSON.stringify(blankCustomerData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(reseCustomerData)

    return {
      isBusy,

      customerData,
      stateOptions,
      branchOptions,
      blankbranchInfo,
      blankCustomerData,
      setCustomerCreate,
      setCustomerUpdate,
      setCustomerPasswordUpdate,
      getMetaListData,

      reseCustomerData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>

<style lang="scss" scoped>
.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}

.user-state-icon {
  max-width: 24px;
  margin-right: 8px;
}

.user-state-icon-hidden {
  opacity: 0 !important;
}
</style>
