import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    customerInfo: null,
    branchOptions: [],
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_CUSTOMER_INFO_STATE(state, val) {
      state.customerInfo = val
    },
    UPDATE_BRANCH_OPTIONS_STATE(state, val) {
      state.branchOptions = val
    },
  },
  actions: {
    // (查詢)會員列表
    getCustomerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/customer', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一會員
    getCustomerData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/customer/${resolveData.customer_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)會員大頭貼
    setCustomerImageUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/avatar`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)會員大頭貼
    setCustomerImageDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/avatar`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)會員
    setCustomerCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/customer', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)會員
    setCustomerUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)會員密碼
    setCustomerPasswordUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/password`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)會員
    setCustomerDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)會員證件
    setCustomerImageKycUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/kyc`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)會員證件
    setCustomerImageKycDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/kyc`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)會員點數操作
    setCustomerPointUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/customer/${resolveData.customer_id}/quota`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
