import { ref, watch } from '@vue/composition-api' // , computed
// import moment from 'moment'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useCustomerSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  // const {
  //   useHttpCodeAlert,
  // } = useAlert()

  const stateOptions = [{
    label: '停用', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const genderOptions = [{
    label: '先生', value: 1,
  }, {
    label: '小姐', value: 2,
  }, {
    label: '其他', value: 0,
  }]

  const ui = {
    stateList: [{
      false: '停用', true: '啟用',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
    genderList: {
      0: '其他', 1: '先生', 2: '小姐',
    },
    kycMode: { account: '信箱驗證', phone: '手機驗證', identity: '身分驗證' },
    kycState: [{
      0: '不需驗證', 1: '尚未驗證', 2: '已驗證', 3: '待驗證',
    }, {
      0: 'secondary', 1: 'danger', 2: 'success', 3: 'warning',
    }],
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    stateOptions,
    genderOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useCustomerList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '會員名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '分站', key: 'branch_id', sortable: true, searchable: true, select: true,
    },
    {
      label: '驗證狀態', key: 'verify', sortable: false, searchable: true, select: true,
    },
    {
      label: '餘額', key: 'point', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次登入', key: 'last_login_ip', sortable: true, searchable: true, select: true,
    },
    // {
    //   label: '上次登入', key: 'last_login_at', sortable: true, searchable: true, select: true,
    // },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchState = ref(null)
  const searchBranch = ref(null)
  const branchOptions = ref([])

  const blankCustomerData = {
    id: null,
    ssid: null,
    image: null,
    account: null,
    name: null,
    sex: null,
    family_name: null,
    branch_id: null,
    last_login_at: null,
    last_login_ip: null,
    register_ip: null,
    email_state: 0,
    kyc_state: 0,
    phone_state: 0,
    state: false,
    created_at: null,
    updated_at: null,
    identity: null, // 1st_identity
    '2nd_identity': null,
    '3rd_identity': null,
    contact: null,
    content: null,
    point: 0,
    tag_id: null,
    remark_display: false,
  }

  const blankbranchInfo = {
    id: null,
    name: null,
    branch: null,
    phone_valid_switch: false,
    mail_valid_switch: false,
  }

  const setCustomerDelete = (...arg) => store.dispatch('admin-customer/setCustomerDelete', ...arg)
  const setCustomerCreate = (...arg) => store.dispatch('admin-customer/setCustomerCreate', ...arg)
  const setCustomerUpdate = (...arg) => store.dispatch('admin-customer/setCustomerUpdate', ...arg)
  const setCustomerPasswordUpdate = (...args) => store.dispatch('admin-customer/setCustomerPasswordUpdate', ...args)

  const getCustomerListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value ? 1 : 0}`
    }

    if (searchBranch.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};branch_id: ${searchBranch.value}`
      } else resolveFilters = `branch_id: ${searchBranch.value}`
    }

    store.dispatch('admin-customer/getCustomerList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, branch_data } = response.data.data

        // Object.keys(branch_data)
        branchOptions.value = branch_data.map(item => ({
          label: item.name,
          key: item.branch,
          icon: item.icon,
          value: item.id,
        }))

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankCustomerData, item),
            branch_info: item.branch_id && item.branch_info ? syncObject(blankbranchInfo, item.branch_info) : syncObject(blankbranchInfo, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange, searchBranch], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchState,
    timeRange,
    blankCustomerData,
    blankbranchInfo,
    searchBranch,
    branchOptions,
    refetchData,
    refetchTable,

    setCustomerDelete,
    setCustomerCreate,
    setCustomerUpdate,
    setCustomerPasswordUpdate,
    getCustomerListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useCustomerViewGeneral = () => {
  const {
    syncObject,
  } = useCommenSettings()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    blankCustomerData,
    blankbranchInfo,
  } = useCustomerList()

  const generalData = ref(null)

  const getCustomerInfoData = (updateData, callback) => {
    // store.commit('admin-customer/UPDATE_BUSY_LOADING_STATE', true)
    store.dispatch('admin-customer/getCustomerData', { ...updateData }) // getCustomerData
      .then(response => {
        const { data, branch_data } = response.data.data
        // const item = data.data[1]
        const resolveData = {
          ...syncObject(blankCustomerData, data),
          branch_info: data.branch_id && data.branch_info ? syncObject(blankbranchInfo, data.branch_info) : syncObject(blankbranchInfo, {}),
        }
        const branchOptions = branch_data.map(item => ({
          label: item.name,
          icon: item.icon,
          value: item.id,
        }))
        store.commit('admin-customer/UPDATE_CUSTOMER_INFO_STATE', resolveData)
        store.commit('admin-customer/UPDATE_BRANCH_OPTIONS_STATE', branchOptions)
        // store.commit('admin-customer/UPDATE_BUSY_LOADING_STATE', false)
        callback()
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const setCustomerUpdate = (...args) => store.dispatch('admin-customer/setCustomerUpdate', ...args)
  const setCustomerPointUpdate = (...args) => store.dispatch('admin-customer/setCustomerPointUpdate', ...args)
  const setCustomerPasswordUpdate = (...args) => store.dispatch('admin-customer/setCustomerPasswordUpdate', ...args)
  const setCustomerImageUpload = (...args) => store.dispatch('admin-customer/setCustomerImageUpload', ...args)
  const setCustomerImageDelete = (...args) => store.dispatch('admin-customer/setCustomerImageDelete', ...args)

  const setCustomerImageKycUpload = (...args) => store.dispatch('admin-customer/setCustomerImageKycUpload', ...args)
  const setCustomerImageKycDelete = (...args) => store.dispatch('admin-customer/setCustomerImageKycDelete', ...args)

  return {
    syncObject,
    getCustomerInfoData,
    setCustomerUpdate,
    setCustomerPointUpdate,
    setCustomerPasswordUpdate,
    setCustomerImageUpload,
    setCustomerImageDelete,
    setCustomerImageKycUpload,
    setCustomerImageKycDelete,
    generalData,
  }
}

export const useBranchCustomerList = branchID => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '會員名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '驗證狀態', key: 'verify', sortable: false, searchable: true, select: true,
    },
    {
      label: '餘額', key: 'point', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchBranch = ref(null)
  const branchOptions = ref([])

  const blankCustomerData = {
    id: null,
    ssid: null,
    image: null,
    account: null,
    name: null,
    sex: null,
    family_name: null,
    branch_id: null,
    last_login_at: null,
    last_login_ip: null,
    register_ip: null,
    email_state: 0,
    kyc_state: 0,
    phone_state: 0,
    state: false,
    created_at: null,
    updated_at: null,
    identity: null, // 1st_identity
    '2nd_identity': null,
    '3rd_identity': null,
    contact: null,
    content: null,
    point: 0,
    tag_id: null,
    remark_display: false,
  }

  const blankbranchInfo = {
    id: null,
    name: null,
    branch: null,
    phone_valid_switch: false,
    mail_valid_switch: false,
  }

  const setCustomerDelete = (...arg) => store.dispatch('admin-customer/setCustomerDelete', ...arg)
  const setCustomerCreate = (...arg) => store.dispatch('admin-customer/setCustomerCreate', ...arg)
  const setCustomerUpdate = (...arg) => store.dispatch('admin-customer/setCustomerUpdate', ...arg)
  const setCustomerPasswordUpdate = (...args) => store.dispatch('admin-customer/setCustomerPasswordUpdate', ...args)

  searchBranch.value = branchID

  const getCustomerListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchBranch.value !== null) {
      resolveFilters = `branch_id: ${searchBranch.value ? 1 : 0}`
    }

    store.dispatch('admin-customer/getCustomerList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total, branch_data } = response.data.data

        // Object.keys(branch_data)
        branchOptions.value = branch_data.map(item => ({
          label: item.name,
          key: item.branch,
          icon: item.icon,
          value: item.id,
        }))

        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankCustomerData, item),
            branch_info: item.branch_id && item.branch_info ? syncObject(blankbranchInfo, item.branch_info) : syncObject(blankbranchInfo, {}),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, timeRange, searchBranch], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    blankCustomerData,
    blankbranchInfo,
    searchBranch,
    branchOptions,
    refetchData,
    refetchTable,

    setCustomerDelete,
    setCustomerCreate,
    setCustomerUpdate,
    setCustomerPasswordUpdate,
    getCustomerListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}
