<template>
  <div>
    <b-collapse :id="randomExplorerId">
      <div class="border p-1">
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="3"
            sm="4"
          >
            <label>長度</label>
          </b-col>

          <b-col
            cols="12"
            md="9"
            sm="8"
          >
            <b-form-input
              v-model="size"
              type="number"
              class="form-control w-50"
              placeholder="密碼長度"
              size="sm"
              @change="changeSize"
            />
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col
            cols="12"
            md="3"
            sm="4"
          >
            <label>字母</label>
          </b-col>

          <b-col
            cols="12"
            md="9"
            sm="8"
          >
            <b-form-radio-group
              v-model="lettersType"
              :options="lettersOptions"
              name="letters-type"
            />
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col
            cols="12"
            md="3"
            sm="4"
          >
            <label>數字和符號</label>
          </b-col>

          <b-col
            cols="12"
            md="9"
            sm="8"
          >
            <b-form-radio-group
              v-model="numbersignType"
              :options="numbersignOptions"
              name="umbersign-type"
            />
          </b-col>
        </b-row>

        <div class="text-right">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="generate"
          >
            生成
          </b-button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BFormInput, BCollapse, BButton, BRow, BCol,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

import { useSwalToast } from '@/libs/mixins/index' // , useAlert

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormInput,
    BCollapse,
  },
  mixins: [useSwalToast],
  props: {
    useCallbackData: {
      type: Boolean,
      default: false,
    },
    randomExplorerId: {
      type: String,
      default: 'random-explorer-modal',
    },
  },
  data() {
    return {
      randomGenerator: null,
      size: 24,
      characters: 'a-z,A-Z,0-9,#',
      lettersType: 'a-z,A-Z',
      lettersOptions: [
        { text: '兩者(aBcD)', value: 'a-z,A-Z' },
        { text: '小寫字母(abcd)', value: 'a-z' },
        { text: '大寫字母(ABCD)', value: 'A-Z' },
      ],
      numbersignType: '0-9,#',
      numbersignOptions: [
        { text: '兩者(1@3$)', value: '0-9,#' },
        { text: '數字(1234)', value: '0-9' },
        { text: '符號(@#$)', value: '#' },
      ],
    }
  },
  methods: {
    // 亂數產密碼
    generate() {
      this.characters = `${this.lettersType},${this.numbersignType}`
      const charactersArray = this.characters.split(',')
      let CharacterSet = ''
      let randpassword = ''

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
      }
      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|'
      }

      for (let i = 0; i < this.size; i += 1) {
        randpassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
      }
      this.randomGenerator = randpassword
      this.$emit('call-back-data', this.randomGenerator)
    },

    // 長度最小限制
    changeSize() {
      if (this.size < 8) this.size = 8
    },

    // (獲取資料)
    getData() {
      this.$root.$emit('bv::toggle::collapse', this.randomExplorerId)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.custom-control-inline {
  margin-bottom: 1rem;
}
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
